import React from "react"
import { css } from "@emotion/core"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const MediaStub = ({ media, dateText }) => {
  const myDate = new Date(media.date);
  const image = getImage(media.image);
  return (
    <article
      css={css`
        @media(min-width:800px){
          display: flex;
          align-items: center;
        }
        margin-top: 0;
      `}
    >
        <GatsbyImage
          image={image}
          css={css`
            margin: 0 1rem 0 0;
            width: 100%;
            height: auto;
            display: block;
            flex: 0 0 200px;
          `}
          alt={media.title}
        />
      
      <div>
        <h3>
          {media.title}
        </h3>
        <b>{media.subtitle}</b>
        <p><small>{dateText}:<br/>{myDate.toLocaleDateString("en-AU")}</small></p>
      </div>
    </article>
  )
}

export default MediaStub
